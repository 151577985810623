<template>
  <div id="app" class="">
    <Header></Header>
    <tab v-if="isShowTab"></tab>
    <router-view class="main"></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./common/header";
import Tab from "./common/tab"
import Footer from "./common/footer";
export default {
  name: "App",
  components: {
    Header,
    Tab,
    Footer,
  },
  data() {
    return {
      isShowTab: false
    }
  },
  watch: {
    "$route"(to, from) {
      console.log(to, from, "app.vue")
      if (to.meta && to.meta.tab) {
        this.isShowTab = true
      } else {
        this.isShowTab = false
      }
    }
  },
  created() {
    if (this.$route.meta && this.$route.meta.tab) {
      this.isShowTab = true;
    }
  }
};
</script>

<style lang="scss">
html{
// font-size:.5rem;
color:rgb(31,33,41);
}
#app{
  width:100%;
  font-size:12px;
}
.main{
  min-height:80vh;
  margin-top:1.5rem;
  padding-top:1.5rem;
  background: #f7f8fc;
}
.b-sidebar .sidebar-content{
  width:100%!important;
}
a{
  text-decoration: none!important;
}
.label,.field-label{
  font-size:12px;
}
.navbar.is-spaced{
  padding:0.1rem!important;
}
html.has-navbar-fixed-top, body.has-navbar-fixed-top{
  padding-top:0!important;
}
h1,.tit{

  width:0!important;
  height:0!important;
 display:inline;
 color:#fff;
}
.field-label{
  margin-right:0.5rem!important;
}
.field-body{
  flex-grow:14!important;
}
.hero{
  margin-bottom:0rem!important;
}
.leftContainer .b-tabs.is-vertical > .tabs ul{
  width:6vw!important;
  li{
    height:16vh;
    background:#fff;
    a{
      display:flex;
      flex-direction:column;
      justify-content:space-around;
    }
  }
}
.leftContainer .tabs a{
  width:4rem!important;
  height:4rem!important;
  margin:0 auto!important;
  border-radius:6px !important;
}
.tabs.is-toggle li.is-active a{
  background:linear-gradient(250deg,#ffd861 -.65%,#ffcc32)!important;
  
}
.tabs.is-toggle li a:hover{
  background:#ffcc3266!important;
 
}

.tabs.is-toggle a{
  border:none!important;

}
.navbar.is-spaced{
  border-bottom:1px solid #ddd;
}
</style>
