<template>
  <section class="sitemap-container">
    <nav class="nav">
      <ul class="nav-list">
        <li class="nav-item" v-for="(item, index) in sitemap" :key="item.name">
          <router-link
            :to="item.path"
            :class="{ active: item.path === $route.path }"
            >{{ item.name }}
            <!-- <span class="arrow" v-if="index !== sitemap.length - 1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10px"
                height="10px"
                viewBox="0 0 8 12"
                fill="#121212"
                aria-hidden="true"
              >
                <path
                  d="M1.08921 0.148971C1.03905 0.106489 0.981007 0.074303 0.918404 0.0542515C0.8558 0.0341999 0.78986 0.0266754 0.724348 0.0321076C0.592041 0.0430785 0.469511 0.106159 0.383714 0.207471C0.297916 0.308784 0.255879 0.44003 0.26685 0.572337C0.277821 0.704644 0.340901 0.827174 0.442214 0.912971L6.46021 5.99997L0.442214 11.087C0.340901 11.1728 0.277821 11.2953 0.26685 11.4276C0.255879 11.5599 0.297916 11.6912 0.383714 11.7925C0.469511 11.8938 0.592041 11.9569 0.724348 11.9678C0.856655 11.9788 0.987901 11.9368 1.08921 11.851L7.55821 6.38197C7.61379 6.33504 7.65846 6.27654 7.68909 6.21056C7.71973 6.14458 7.73561 6.07272 7.73561 5.99997C7.73561 5.92723 7.71973 5.85536 7.68909 5.78938C7.65846 5.7234 7.61379 5.66491 7.55821 5.61797L1.08921 0.148971Z"
                ></path>
              </svg> </span
          > -->
        </router-link>
        </li>
      </ul>
    </nav>
    <div class="content">
      <div class="content-list" v-if="!isHideContentList">
        <div class="content-item" v-for="item in contentList" :key="item.name">
          <router-link
            :to="{ name: item.path2, params: { name: item.name } }"
            >{{ item.name }}</router-link
          >
        </div>
      </div>
      <div class="content-view" v-if="isHideContentList">
        <div class="menu-list">
          <div class="menu-item" v-for="item in contentList" :key="item.name">
            <router-link
              :class="{ active: item.name === $route.params.name }"
              :to="{ name: item.path2, params: { name: item.name } }"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "sitemap",
  data() {
    return {
      sitemap: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Sitemap",
          path: "/sitemap",
        },
      ],
      contentList: [
        {
          name: "Hots",
          path: "/hots",
          path2: "mapContent",
        },
        {
          name: "Blogs",
          path: "/blogs",
          path2: "mapContent",
        },
        {
          name: "News",
          path: "/news",
          path2: "mapContent",
        },
        {
          name: "FAQ",
          path: "/faq",
          path2: "mapContent",
        },
        {
          name: "Groups",
          path: "/groups",
          path2: "mapContent",
        },
        {
          name: "Tools",
          path: "/tools",
          path2: "mapContent",
        },
      ],
      isHideContentList: false,
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.isHideContentList = to.path !== "/sitemap";
      if (this.isHideContentList) {
        const map = {
          name: to.params.name,
          path: to.path,
        };
        this.sitemap.splice(2, 0, map);
        this.sitemap.splice(3, this.sitemap.length - 3);
      } else {
        this.sitemap.pop();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sitemap-container {
  margin: 60px 30px 30px 30px;
  background-color: initial;
  .nav {
    width: 100%;
    margin-bottom: 30px;
    .nav-list {
      display: flex;
      align-items: center;
      .nav-item {
        a {
          font-size: 16px;
          font-weight: 600;
          color: #121212;
          cursor: pointer;
          &.active {
            color: #0057d9;
          }
          .arrow {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .content-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .content-item {
      width: 32%;
      height: 400px;
      background-color: #f8fafc;
      border-radius: 10px;
      position: relative;
      a {
        font-size: 24px;
        font-weight: 600;
        color: #121212;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
  .content-view {
    display: flex;
    .menu-list {
      display: flex;
      flex-direction: column;
      gap: 8px 0;
      width: 251px;
      .menu-item {
        a {
          display: block;
          width: 50%;
          height: 36px;
          border-radius: 4px;
          padding: 8px 12px;
          font-size: 14px;
          font-weight: 600;
          &.active {
            background-color: #fafafc;
            color: #0057d9;
          }
          &:hover {
            background-color: #fafafc;
          }
        }
      }
    }
  }
}
</style>
