<template>
  <section class="map-content-container">
    <h1 class="title">{{ $route.params.name }}</h1>
    <div class="content-box" v-for="item in list" :key="index">
      <div class="header">{{ item.name }}</div>
      <div class="list">
        <div class="item" v-for="subItem in item.city" :key="subItem.name">
          <router-link :to="subItem.name">{{ subItem.name }}</router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "mapContent",
  data() {
    return {
      list: [
        {
          name: "China",
          city: [
            {
              name: "Beijing",
            },
            {
              name: "Shanghai",
            },
            {
              name: "Hong Kong",
            },
            {
              name: "Taiwan",
            },
            {
              name: "Guangzhou",
            },
            {
              name: "Shenzhen",
            },
          ],
        },
        {
          name: "India",
          city: [
            {
              name: "Delhi",
            },
            {
              name: "Mumbai",
            },
            {
              name: "Kolkata",
            },
            {
              name: "Chennai",
            },
            {
              name: "Bangalore",
            },
            {
              name: "Hyderabad",
            },
            {
              name: "Ahmedabad",
            },
          ],
        },
        {
          name: "USA",
          city: [
            {
              name: "New York",
            },
            {
              name: "Los Angeles",
            },
            {
              name: "Chicago",
            },
            {
              name: "Houston",
            },
          ],
        },
        {
          name: "UK",
          city: [
            {
              name: "London",
            },
            {
              name: "Manchester",
            },
            {
              name: "Birmingham",
            },
            {
              name: "Glasgow",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.map-content-container {
  flex: 1;
  .title {
    font-size: 40px;
    color: #000;
  }
  .content-box {
    padding: 24px 0;
    border-bottom: 1px solid #dae0e6;
    .header {
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      color: #000;
      font-weight: 600;
    }
    .list {
      margin-top: 10px;
      display: flex;
      gap: 8px 5px;
      flex-wrap: wrap;
      .item {
        width: 24%;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        a {
          color: #374559;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
</style>
