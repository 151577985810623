<template>
    <div class="subSearch">

        <div class="top">
            <ul class="hotCountries">
                <li v-for="item in tabs" :key="item.id" :class="{ active: state.tab === item.id }"
                    @click="clickTab(item.id)">
                    <p>{{ item.name }}</p>
                    <p></p>
                </li>
            </ul>
            <div class="advert">文字广告位</div>
        </div>

        <div class="conditionBox">
            <div class="condition">
                <div>Country</div>
                <ul>
                    <li v-for="item in peoArray" :key="item" @click="clickPeo(item)"
                        :class="{ active: state.peo === item }">{{ item }}</li>
                </ul>
            </div>
            <div class="condition1">
                <div>
                    <b-switch v-model="showCities"></b-switch>
                    <p>show cities</p>
                </div>
                <div>
                    <ul>
                        <li v-for="item in countries" :key="item.id" @click="clickCountry(item)"
                            :class="{ active: state.country === item.id }">{{ item.name }}</li>
                    </ul>
                    <ul v-if="showCities">
                        <li v-for="item in hotCities" :key="item.id" @click="clickCity(item)"
                            :class="{ active: state.city === item.id }">{{ item.name }}</li>
                    </ul>
                </div>

            </div>
            <div class="condition">
                <div>Trip type</div>
                <ul>
                    <li v-for="item in triptypeList" :key="item.id" @click="clickType(item)"
                        :class="{ active: state.triptype === item.id }">{{ item.name }}</li>
                </ul>
            </div>
        </div>
        <div class="conditionBox" v-if="viewMore">
            <div class="condition">12312123</div>
        </div>
        <div class="more">
            <div @click="viewMore = !viewMore">
                <span>More(keyword/tag..)</span>
                <svg v-if="viewMore" t="1731271494537" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="2361" width="16" height="16">
                    <path
                        d="M952.32 715.2l-416-485.376c-12.16-14.176-36.448-14.176-48.608 0l-416 485.376c-8.128 9.472-9.984 22.848-4.768 34.176C72.16 760.704 83.488 768 96 768l832 0c12.512 0 23.84-7.296 29.056-18.624S960.448 724.672 952.32 715.2z"
                        fill="#5D646F" p-id="2362"></path>
                </svg>
                <svg v-else t="1731271553413" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="2559" width="16" height="16">
                    <path
                        d="M957.056 338.624C951.84 327.296 940.512 320 928 320L96 320c-12.512 0-23.84 7.296-29.088 18.624-5.216 11.36-3.328 24.704 4.768 34.208l416 485.344c6.08 7.104 14.944 11.2 24.288 11.2s18.208-4.096 24.288-11.2l416-485.344C960.448 363.328 962.272 349.984 957.056 338.624z"
                        fill="#5D646F" p-id="2560"></path>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { getCityList } from '@/api/discover'
import { peoArrayList } from '@/common/commonData'
import { convertInitial } from "@/utils/utils"
export default {
    computed: {
        state() {
            return this.$store.state.discover.state
        },
        hotCities() {
            return this.$store.state.discover.hotCities
        }
    },
    watch: {
        state: {
            handler: function (nv, ov) {
                sessionStorage.setItem('discover', JSON.stringify(nv))
            },
            deep: true
        }
    },
    data() {
        return {
            //tabs
            tabs: [
                {
                    name: "Blogs",
                    id: '1'
                },
                {
                    name: "Offers",
                    id: '2'
                },
                {
                    name: "tab3",
                    id: '3'
                },
                {
                    name: "tab4",
                    id: '4'
                },
                {
                    name: "tab5",
                    id: '5'
                },
            ],
            //国家
            peoArray: ['不限'],
            countries: [],
            //城市
            // hotCities: [],
            //旅游类型
            triptypeList: [
                {
                    name: 'food&drink',
                    id: "329"
                },
                {
                    name: 'health&wellness',
                    id: "328"
                },
                {
                    name: 'nature&adventure',
                    id: "327"
                },
                {
                    name: 'nature&adventure',
                    id: "326"
                }
            ],
            activeType: {},
            showCities: false,

            viewMore: false
        }
    },
    methods: {
        clickTab(id) {
            this.state.tab = id
        },
        clickPeo(item) {
            this.state.peo = item
            this.state.country = ''
            this.state.countryName = ''
            this.state.city = ''
            this.state.cityName = ''
            this.$store.commit("setHotCities", []);
            this.countries = peoArrayList.find((v) => {
                if (this.state.peo === '不限' || this.state.peo === 'Hot') {
                    if (v.key === "*") {
                        return v
                    }
                }
                if (v.key === this.state.peo) {
                    return v
                }

            }).list;
        },
        async clickCountry(item) {
            this.state.country = item.id
            this.state.countryName = item.name
            this.state.city = ''
            this.state.cityName = ''
            let result = await getCityList({
                parentId: item.id
            })
            if (result) {
                this.$store.commit("setHotCities", result);
            }
        },
        clickCity(item) {
            this.state.city = item.id
            this.state.cityName = item.name
        },
        clickType(item) {
            this.state.triptype = item.id
            this.state.triptypeName = item.name
        }
    },

    async created() {
        let nameParams = Object.values(this.$route.params)

        this.state.peo = 'Hot'
        if (this.$route.query.peo) {
            if (this.$route.query.peo === '*') {
                this.state.peo = 'Hot'
            } else {
                this.state.peo = convertInitial(this.$route.query.peo)
            }
        }
        //传国家必须传peo
        if (this.$route.query.country) {
            this.state.country = this.$route.query.country
            let parts = nameParams[0].split('-')
            this.state.countryName = convertInitial(parts[parts.length - 1])
        }
        //传城市必须传国家
        if (this.$route.query.city) {
            this.state.city = Number(this.$route.query.city)
            this.state.cityName = convertInitial(nameParams[1])
            this.showCities = true
        }
        //有国家有城市有旅游类型
        if (this.$route.query.city && this.$route.query.triptype) {
            this.state.triptype = this.$route.query.triptype
            this.state.triptypeName = convertInitial(nameParams[2])
        }
        //有国家没城市有旅游类型
        if (this.$route.query.country && !this.$route.query.city && this.$route.query.triptype) {
            this.state.triptype = this.$route.query.triptype
            this.state.triptypeName = convertInitial(nameParams[1])
        }
        //没国家没城市有旅游类型
        if (!this.$route.query.country && this.$route.query.triptype) {
            this.state.country = this.$route.query.country
            this.state.countryName = convertInitial(nameParams[0])
        }

        peoArrayList.forEach((ele) => {
            if (ele.key === "*") {
                this.peoArray.push('Hot')
                this.countries = ele.list
            } else {
                this.peoArray.push(ele.key)
            }
        });

        if (this.state.country) {
            let result = await getCityList({
                parentId: this.state.country
            })
            if (result) {
                this.$store.commit("setHotCities", result);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/reset.scss');

div,
ul,
li,
p {
    margin: 0;
    padding: 0;
}

.subSearch {
    // width: 1200px;
    // margin: 0 auto;
    background: #fff;
    margin-top: 25px;
    padding: 20px;
    padding-bottom: 0;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(197, 193, 193, 0.929);

        .hotCountries {
            display: flex;
            margin: 0;

            li {
                margin: 0;
                padding: 0;
                margin-right: 30px;
                cursor: pointer;
                text-align: center;

                p {
                    font-size: 16px !important;
                    padding: 0;
                    margin: 0;

                    &:nth-child(1) {
                        margin-bottom: 6px;
                        font-weight: 500;
                    }
                }
            }

            .active {
                p:nth-child(1) {
                    font-weight: 600;
                }

                p:nth-child(2) {
                    height: 4px;
                    width: 100%;
                    background: yellowgreen;
                    border-radius: 2px;
                }
            }
        }

        .advert {
            background: greenyellow;
            padding: 10px 16px;
        }
    }

    .conditionBox {
        .condition {
            display: flex;
            align-items: center;
            margin-top: 20px;

            &>div {
                width: 80px;
                color: #979aa8;
            }

            &>ul {
                display: flex;
                align-items: center;

                li {
                    margin-right: 30px;
                    padding: 2px 5px;
                    cursor: pointer;
                }

                .active {
                    background: #ffcc32;
                }
            }
        }

        .condition1 {
            display: flex;
            align-items: flex-start;
            margin-top: 10px;

            &>div:nth-child(1) {
                width: 80px;
                color: #979aa8;
            }

            &>div:nth-child(2) {
                padding: 8px;
                border-radius: 3px;
                flex: 1;
                background: #f7f8fc;

                &>ul {
                    display: flex;
                    align-items: center;

                    flex-wrap: wrap;

                    &:nth-child(1) {
                        margin: 0;

                        li {
                            margin-top: 0;
                        }
                    }

                    li {
                        margin-right: 30px;
                        padding: 2px 5px;
                        margin-top: 10px;
                        cursor: pointer;
                    }

                    .active {
                        background: #ffcc32;
                    }
                }
            }

        }
    }

    .more {
        text-align: center;
        padding: 10px;
        font-size: 18px;
        margin-top: 20px;
        border-top: 1px solid rgba(197, 193, 193, 0.929);
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            cursor: pointer;
            display: flex;
            align-items: center;

            span {
                font-size: 14px;
            }

            .icon {
                margin-left: 10px;
                width: 12px;
            }
        }
    }
}
</style>