// index.js,我放在根目录下/store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import discover from './discover.js'

export default new Vuex.Store({
    modules:{
        discover
    }
})
