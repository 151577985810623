<template>
  <div class="articleListWrapper">
    <div class="leftContainer">
      <div class="leftContainerTab">
        <div class="leftContainerTabItem" :class="checkTabName == 'Hot' ? 'checkactive' : ''" @click="handleToggleTab('Hot')">
          <div class="content">
            <!-- <b-icon icon="google-photos"></b-icon> -->
            <span> Hot </span>
          </div>
        </div>
        <div
          class="leftContainerTabItem"
          :class="checkTabName == 'Discover' ? 'checkactive' : ''"
          @mouseenter="handleMenuEnter"
          @mouseleave="isShowHotCountry = false"
          @click="handleToggleTab('Discover')"
        >
          <div class="content">
            <!-- <b-icon icon="google-photos"></b-icon> -->
            <span> Discover </span>
          </div>
          <div class="concatWrapper" v-if="isShowHotCountry">
            <div class="concatWrapperHeader">Hot Country</div>
            <div class="concatWrapperMenu">
              <ul class="concatWrapperNav">
                <li
                  v-for="(item, index) in letterList"
                  :class="{ active: activeNavIndex == index }"
                  :key="item"
                  @click.stop="scrollOn(item, index)"
                >
                  {{ item }}
                </li>
              </ul>
              <ul
                class="concatWrapperNavMenu"
                id="concatWrapperNavMenu"
                @scroll="scrollMenu"
              >
                <li
                  v-for="item in peoArrayList"
                  :key="item.key"
                  class="concatWrapperNavMenuItem"
                >
                  <div class="navMenuKey">{{ item.key }}</div>
                  <div
                    class="navMenuList"
                    v-for="curr in item.list"
                    :key="curr.id"
                    @click.stop="handleChooseCountry(curr, item)"
                  >
                    {{ curr.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-for="item in menuList"
          :key="item"
          class="leftContainerTabItem"
          :class="checkTabName == item ? 'checkactive' : ''"
          @click="handleToggleTab(item)"
        >
          <div class="content">
            <!-- <b-icon icon="google-photos"></b-icon> -->
            <span> {{ item }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabComponent",
  data() {
    return {
      menuList: ["Groups", "FAQ", "News"],
      isShowHotCountry:false,
      peoArrayList: [],
      peoArrayList: [
        {
          key: "*",
          list: [
            {
              name: "China",
              id: "285",
            },
            {
              name: "Indonesia",
              id: "18",
            },
            {
              name: "Japan",
              id: "305",
            },
            {
              name: "Korea",
              id: "15",
            },
            {
              name: "Thailand",
              id: "14",
            },
            {
              name: "Vietnam",
              id: "16",
            },
          ],
        },
        {
          key: "A",
          list: [
            {
              name: "Afghanistan",
              id: "294",
            },
            {
              name: "Armenia",
              id: "295",
            },
            {
              name: "Azerbaijan",
              id: "296",
            },
          ],
        },
        {
          key: "B",
          list: [
            {
              name: "Bahrain",
              id: "297",
            },
            {
              name: "Bangladesh",
              id: "28",
            },
            {
              name: "Bhutan",
              id: "298",
            },
            {
              name: "Brunei",
              id: "299",
            },
          ],
        },
        {
          key: "C",
          list: [
            {
              name: "Cambodia",
              id: "25",
            },
            {
              name: "China",
              id: "285",
            },
            {
              name: "Cyprus",
              id: "300",
            },
          ],
        },
        {
          key: "E",
          list: [
            {
              name: "East timor",
              id: "301",
            },
          ],
        },
        {
          key: "G",
          list: [
            {
              name: "Georgia",
              id: "302",
            },
          ],
        },
        {
          key: "I",
          list: [
            {
              name: "India",
              id: "18",
            },
            {
              name: "Indonesia",
              id: "19",
            },
            {
              name: "Iran",
              id: "303",
            },
            {
              name: "Iraq",
              id: "304",
            },
            {
              name: "Israel",
              id: "29",
            },
          ],
        },
        {
          key: "J",
          list: [
            {
              name: "Japan",
              id: "33",
            },
            {
              name: "Jordan",
              id: "306",
            },
          ],
        },
        {
          key: "K",
          list: [
            {
              name: "Kazakhstan",
              id: "306",
            },
            {
              name: "Korea",
              id: "15",
            },
            {
              name: "Kuwait",
              id: "307",
            },
            {
              name: "Kyrgyzstan",
              id: "308",
            },
          ],
        },
        {
          key: "L",
          list: [
            {
              name: "Laos",
              id: "24",
            },
            {
              name: "Lebanon",
              id: "309",
            },
          ],
        },
        {
          key: "M",
          list: [
            {
              name: "Malaysia",
              id: "17",
            },
            {
              name: "Maldives",
              id: "30",
            },
            {
              name: "Mongolia",
              id: "310",
            },
            {
              name: "Myanmar",
              id: "27",
            },
          ],
        },
        {
          key: "N",
          list: [
            {
              name: "Nepal",
              id: "23",
            },
            {
              name: "North Korea",
              id: "311",
            },
          ],
        },
        {
          key: "O",
          list: [
            {
              name: "Oman",
              id: "312",
            },
          ],
        },
        {
          key: "P",
          list: [
            {
              name: "Pakistan",
              id: "31",
            },
            {
              name: "Palestine",
              id: "313",
            },
            {
              name: "Philippines",
              id: "22",
            },
          ],
        },
        {
          key: "Q",
          list: [
            {
              name: "Qatar",
              id: "33",
            },
          ],
        },
        {
          key: "S",
          list: [
            {
              name: "Saudi Arabia",
              id: "32",
            },
            {
              name: "Singapore",
              id: "20",
            },
            {
              name: "Sri Lanka",
              id: "26",
            },
            {
              name: "Syria",
              id: "314",
            },
          ],
        },
        {
          key: "T",
          list: [
            {
              name: "Tajikistan",
              id: "315",
            },
            {
              name: "Thailand",
              id: "14",
            },
            {
              name: "Turkey",
              id: "316",
            },
            {
              name: "Turkmenistan",
              id: "317",
            },
          ],
        },
        {
          key: "U",
          list: [
            {
              name: "United Arab Emirates",
              id: "21",
            },
            {
              name: "Uzbekistan",
              id: "318",
            },
          ],
        },
        {
          key: "V",
          list: [
            {
              name: "Vietnam",
              id: "16",
            },
          ],
        },
        {
          key: "Y",
          list: [
            {
              name: "Yemen",
              id: "293",
            },
          ],
        },
      ],
      activeNavIndex: 0,
      checkTabName: "",
      letterList: []
    }
  },
  watch: {
    "$route"(to, from) {
      if (to.meta && to.meta.tab) {
        this.checkTabName = to.meta.tab
      } else {
        this.checkTabName = ""
      }
    },
  },
  created() {
    if (this.$route.meta && this.$route.meta.tab) {
      this.checkTabName = this.$route.meta.tab;
    }
    // 获取26个英文字母大写
    this.peoArrayList.forEach((ele) => {
      this.letterList.push(ele.key);
    });
  },
  methods: {
    handleChooseCountry(curr, val) {
      console.log(curr, val)
      this.$router.push({
        path: `/travel/popular-country/${this.replaceSpacesWithDash(curr.name)}`,
        query: {
          country: curr.id,
          peo: val.key + "" === "*" ? 'Hot' : val.key
        }
      })
      // let routeData = this.$router.resolve(({
      //   path: `/travel/popular-country/${this.replaceSpacesWithDash(curr.name)}`, // path 要跳转的路由地址
      //   query: {
      //     country: curr.id,
      //     peo: val.key + "" === "*" ? 'Hot' : val.key
      //   }
      // }))
      // window.open(routeData.href,'_blank')
    },
    replaceSpacesWithDash(input) {
      return input.replace(/\s+/g, '-');
    },
    handleToggleTab(val) {
      switch(val) {
        case "Hot": {
          this.$router.push({
            path: "/"
          })
          break;
        }
        case "Discover": {
          this.$router.push({
            path: "/discover"
          })
          break;
        }
        case "Groups": {
          this.$router.push({
            path: "/groups"
          })
          break;
        }
        case "FAQ": {
          this.$router.push({
            path: "/"
          })
          break;
        }
        case "News": {
          this.$router.push({
            path: "/"
          })
          window.open(routeData.href,'_blank')
          break;
        }
      }
      
    },
    handleMenuEnter() {
      this.isShowHotCountry = true;
    },
    scrollMenu(e) {
      const scrollItems = document.querySelectorAll(
        ".concatWrapperNavMenuItem"
      );
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        const judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop - 44;
        if (judge) {
          this.activeNavIndex = i;
          break;
        }
      }
    },
    scrollOn(val, index) {
      this.activeNavIndex = index;
      let jump = document.getElementsByClassName("concatWrapperNavMenuItem");
      const ele = document.getElementById("concatWrapperNavMenu");
      // 获取需要滚动的距离
      let total = jump[index].offsetTop - 44;
      ele.scrollTop = total;
      ele.animate({ scrollTop: total }, 400);
      console.log(total, "2313213039213921939123-----");
    },
  }
}
</script>

<style lang="scss">
.articleListWrapper {
  display: flex;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 3.8rem;
  bottom: 0rem;
  z-index: 100000 !important;
  .leftContainer {
    .leftContainerTab {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: #fff;
      .leftContainerTabItem {
        display: flex;
        flex-direction: column;
        color: #1f2129;
        position: relative;
        cursor: pointer;
        box-sizing: border-box;
        padding: 1rem;
        position: relative;
        .content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          width: 4rem;
          height: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        }
        &.checkactive {
          .content {
            color: #fff;
            background-color: rgba(255, 204, 50, 1);
          }
        }
        // <!-- 229,88,0 rgba(255,204,50,.12) -->
        &:hover {
          .content {
            color: rgba(229,88,0, 1);
            background-color: rgba(255,204,50,.12);
          }
        }
        span {
          font-size: 0.8rem;
        }
      }
    }
    .tabItemContainer {
      height: 15vh;

      &.hotWrapper {
        position: relative;
      }
    }
  }
}
.concatWrapper {
  width: 26.5rem;
  height: 31.25rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  background: #fff;
  z-index: 10000000 !important;
  box-sizing: border-box;
  padding: 0.5rem;
  top: -5.8rem;
  left: 6rem;
  // display: none;
  .concatWrapperHeader {
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
  }
  .concatWrapperMenu {
    flex: 1;
    display: flex;
    overflow: hidden;
    .concatWrapperNav {
      width: 50px;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #ddd;
      li {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        &:hover {
          background: rgb(255, 225, 0);
        }
        &.active {
          background: #ffcc32;
        }
      }
    }
    .concatWrapperNavMenu {
      flex: 1;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        width: 5px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 10px;
        border-radius: 3px;
        background-color: #d8d8d8;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }
      &::-webkit-scrollbar-track {
        width: 5px;
        background: transparent;
      }
      li {
        width: 100%;
        .navMenuKey {
          width: 100%;
          background-color: rgb(247, 248, 252);
          position: sticky;
          top: -1px;
          line-height: 28px;
          height: 28px;
          font-size: 12px;
          color: rgb(31, 33, 41);
          padding-left: 12px;
          text-align: left;
          border-top: 1px solid rgba(216, 216, 216, 0.6);
          border-right: 1px solid rgba(216, 216, 216, 0.6);
        }
        .navMenuList {
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          font-size: .8rem;
          border-top: 1px solid rgba(216, 216, 216, 0.6);
          border-right: 1px solid rgba(216, 216, 216, 0.6);
          text-align: left;
          padding-left: 20px;
          color: #000;
        }
      }
    }
  }
}
</style>