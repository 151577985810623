<template>

    <div class="library">
        <div class="libraryView">
            <SubSearch></SubSearch>
            <Tags></Tags>
            <!-- <UserData></UserData> -->
            <SearchResult></SearchResult>
        </div>
    </div>
</template>

<script>
import SubSearch from './components/subSearch/index.vue'
import Tags from './components/tags/index.vue'
import UserData from './components/userData/index.vue'
import SearchResult from './components/searchResult/index.vue'
export default {
    components: {
        SubSearch,
        Tags,
        UserData,
        SearchResult
    },
    beforeCreate(){
        if(sessionStorage.getItem('discover')){
            this.$store.state.discover.state = JSON.parse(sessionStorage.getItem('discover'))
        }
    }
}
</script>

<style lang="scss" scoped>
.library {
    .libraryView {
        // padding: 10px;
        // min-height: 190vh;
        // min-width: 100%;
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 40px;
    }
}
</style>