<template>
  <div class="container articleListContainer">
    <Menu class="menuBar"></Menu>
    <div class="searchPanel">
      <searchPanel></searchPanel>
    </div>
    <div class="articleList">
      <div class="mainContent level">
        <div class="level-item mainLeft">
          <prop class="propContainer"></prop>
        </div>
        <div class="level-item mainRight" >
          <mainRight></mainRight>
        </div>
      </div>

    </div>
    
    <div class="nativecreationContainer ">
      <nativecreation></nativecreation>
    </div>
    <div class="bannerAds">
      <bannerAds></bannerAds>
    </div>
    <div class="videoContent">
    </div>
  </div>
</template>

<script>
import prop from "./prop";
import searchPanel from "./searchPanel.vue";
import mainRight from './mainRight.vue';
import nativecreation from './nativecreation';
import bannerAds from './bannerAds'
import videoContent from './videoContent'
import Menu from './menu.vue'
export default {
  data() {
    return {
      
    };
  },
  components: { prop, searchPanel,mainRight,nativecreation,bannerAds,videoContent,Menu},
  methods: {
    backgroundImage(index) {
      if (index < 4) {
        return require(`@/assets/img/${index + 1}.jpg`);
      } else {
        return require(`@/assets/img/${(index % 4) + 1}.jpg`);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.articleListContainer {
  padding-top: 2rem;
  min-height: 190vh;
  min-width: 100%;
  .menuBar{
    position: fixed;
    top:4rem;
    left:0;
  }
  .searchPanel {
    width: 93%;
    margin: 0 auto;
    margin-left: 7%;
    // border:1px solid #ddd;
  }
  .articleList {
    margin-top: 1.5rem;
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
    .leftContainer {
      height: 100vh;
      background: #f7f8fc;
      box-sizing: border-box;
      // border: 1px solid red;
      width: 8%;
      position: fixed;
      left: 0;
      top: 3.25rem;
      margin-right:1rem;
     
      .tabItemContainer{
        height:15vh;

      }
      // border-top-right-radius:6px;
      // border-bottom-right-radius:6px;
    }
    .mainContent {
      width: 93%;
      margin-left: 7%;
      margin-right: 1rem;
      // min-height:30vh;
      .mainLeft {
        width: 45%;
      }
      .mainRight {
        width: 55%;
        height: 100%;
        // border: 1px solid red;
        // padding: 1rem;
        box-sizing: border-box;
        padding:0;
        margin-right: 1rem;
        // margin-left:1rem;
      }
    }
  }
  .nativecreationContainer,.bannerAds,.videoContent{
    margin-left:7%;
    margin-right:1rem;
    margin-top:1rem;
    min-height:15vh;
    // border:1px solid red;
  }
  .bannerAds{
    
  }
  .videoContent{
    // height:40vh;
  }
}

/* 手机设备（max-width: 767px） */
@media only screen and (max-width: 767px) {
  .articleListContainer {
    padding-top: 0rem;
    .searchPanel {
      width: 100% !important;
      margin-left: 0rem !important;
    }
    .articleList {
      .leftContainer,
      .rightContainer {
        display: none;
      }
      .mainContent {
        width: 100%;
        .mainLeft,
        .mainRight {
          width: 100% !important;
        }
      }
    }
  }
}

/* 平板设备（min-width: 768px）到（max-width: 1024px） */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .articleListContainer {
    border: 1px solid red;
    width: 100vw;
    .searchPanel {
      width: 100% !important;
      margin-left: 0rem !important;
    }
    .articleList {
      .leftContainer,
      .rightContainer {
        display: none;
      }
      .mainContent {
        width: 100%;
        .mainLeft,
        .mainRight {
          width: 100% !important;
        }
      }
    }
  }
}

/* 小型桌面（min-width: 1025px）到（max-width: 1200px） */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .mainLeft {
    width: 50%;
  }
  .mainRight {
    width: 40%;
  }
}

/* 标准桌面（min-width: 1200px）到（max-width: 1440px） */
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .mainLeft {
    width: 50%;
  }
  .mainRight {
    width: 40%;
  }
}

/* 大屏桌面（min-width: 1440px） */
@media only screen and (min-width: 1440px) {
}
</style>