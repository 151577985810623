<template>
  <div class="menuContainer">
    <div class="leftContainer">
      <!-- <div class=" "> -->
      <b-tabs
        type="is-toggle is-toggle-rounded"
        :expanded="expanded"
        vertical
        size="is-medium"
      >
   
        <b-tab-item
          class="tabItemContainer"
          icon="google-photos"
          v-for="tab in menuList"
          :key="tab"
          :disabled="tab == 'Me' || tab == 'tools' || tab == 'groups'"
        >
          <!-- :label="tab" -->
          <template #header>
            <span class="item" @click="jump(tab)"> {{ tab }}</span>
          </template>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        "Hots",
        "Blogs",
        "News",
        "FAQ",
        "Groups",
        "Tools",
        // "Games",
        // "Me"
      ],
      expanded: false,
    };
  },
  methods: {
    jump(tab) {
      tab = tab.toLowerCase();
      this.$router.push("/" + tab);
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  min-width: 2rem;
  min-height: 2rem;
  color: #606370;
}
.tabItemContainer {
  background: transparent;
}
</style>