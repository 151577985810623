<template>
  <div class="articleDetailContainer">
    <div class="articleDetailHeader">
      <a class="headerTitle">
        <h2>China Explorers</h2>
      </a>
      <a class="tip">
        <span>23w lively</span>
      </a>
    </div>
    <div class="articleDetailContent">
      <div class="articleDetailContentLeft">
        <ul class="articleDetailContentLeftList">
          <li class="articleDetailContentLeftItem">
            <div class="userInfo">
              <div class="userAvatar">
                <img src="@/assets/img/avtar.png" alt="">
              </div>
              <p class="userName">
                <a>刚刚好</a>
              </p>
              <div class="fllow">Fllow TA</div>
            </div>
            <div class="contentInfo">
              <div class="infoTitle">Released on November 16th: China Explorers</div>
              <div class="infoDetail">Dive into a curated list of top countries to visit and uncover the hidden gems each destination has to offer.</div>
              <div class="tip">
                <p>10w Comment</p>
                <p>10w Likes</p>
              </div>
              <div class="fllow-content">
                <textarea class="textarea-wrapper" placeholder="Enter~~~~"></textarea>
                <div class="fllow-footer">
                  <div class="footer-button">Publish</div>
                </div>
              </div>
            </div>
          </li>
          <li class="articleDetailContentLeftItem">
            <div class="userInfo">
              <div class="userAvatar">
                <img src="@/assets/img/avtar.png" alt="">
              </div>
              <p class="userName">
                <a>刚刚好</a>
              </p>
              <div class="fllow">Fllow TA</div>
            </div>
            <div class="contentInfo">
              <div class="infoTitle black">China Explorers</div>
              <div class="infoTip">
                <div class="date">Released on November 16th</div>
                <div class="tip">
                  <p>10w Comment</p>
                  <p>10w Likes</p>
                </div>
              </div>
              <ul class="infoList">
                <li class="infoItem">
                  <div class="userContent">
                    <div class="userPic">
                      <img src="@/assets/img/avtar.png" alt="">
                    </div>
                    <p class="nameUser">
                      <a>刚刚好</a>
                    </p>
                  </div>
                  <div class="userIssue">
                    <div class="infoTitle black">China Explorers</div>
                    <div class="infoTip">
                      <div class="date">Released on November 16th</div>
                      <div class="tip">
                        <p>Reply</p>
                        <p>Comment</p>
                      </div>
                    </div>
                    <div class="tlakWrapper">
                      <b-button class="btn">Few Words</b-button>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- <div class="fllow-content">
                <textarea class="textarea-wrapper" placeholder="Enter~~~~"></textarea>
                <div class="fllow-footer">
                  <div class="footer-button">Publish</div>
                </div>
              </div> -->
            </div>
          </li>
        </ul>
      </div>
      <div class="articleDetailContentRight">
        <div class="articleDetailContentRightTop">
          <h2>China Explorers Hot Tlak</h2>
          <ul class="articleDetailContentRightTopList">
            <li v-for="(item, index) in talkList" :key="index">
              <div class="imgPic">
                <img srcset="https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=372&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 372w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=672&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 672w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=744&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 744w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 972w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1272w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1344&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1344w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1572&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1572w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1872&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1872w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1944w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=2172&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 2172w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=2400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 2400w" alt="">
              </div>
              <div class="articleDetailInfo">
                <h3>{{ item.title }}</h3>
                <div class="contentInfo">
                  {{ item.des }}
                </div>
                <div class="tip">
                  <p>{{item.participationCount}}w Participation</p>
                  <p>{{item.readCount }}w Read</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="articleDetailContentRightTop">
          <h2>China Explorers Hot</h2>
          <ul class="articleDetailContentRightTopList">
            <li v-for="(item, index) in talkList" :key="index">
              <div class="articleDetailInfo" style="margin-left: 0;">
                <div class="contentInfo">
                  {{ item.des }}
                </div>
                <div class="tip">
                  <p>{{item.participationCount}}w Comment</p>
                  <p>{{item.readCount }}w Likes</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      talkList: [
        {
          title: "Explore Popular Countries",
          des: "Dive into a curated list of top countries to visit and uncover the hidden gems each destination has to offer.",
          participationCount: 25,
          readCount: 981
        },
        {
          title: "Trending Travel Insights",
          des: "Stay with the latest and most engaging travel stories with unique insights and inspiration for your next journey.",
          participationCount: 15,
          readCount: 367
        },
        {
          title: "Top Attractions to Visit",
          des: "Explore must-see attractions around the world, with recommendations and tips for making the most of your visit.",
          participationCount: 10,
          readCount: 256
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.articleDetailContainer {
  min-height: 190vh;
  min-width: 100%;
  box-sizing: border-box;
  padding: 2rem 5rem;
  .articleDetailHeader {
    display: flex;
    align-items: center;
    .headerTitle {
      color: #000 !important;
      h2 {
        font-size: 1.6rem;
      }
      &:hover {
        color: #e65800 !important;
        transition: color 0.5s;
      }
    }
    .tip {
      color: #969aa9 !important;
      font-size: 1rem;
      margin-left: 3rem;
      &:hover {
        color: #e65800 !important;
        transition: color 0.5s;
      }
    }
  }
  .articleDetailContent {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .articleDetailContentLeft {
      width: 67.5%;
      .articleDetailContentLeftList {
        width: 100%;
        .articleDetailContentLeftItem {
          display: flex;
          margin-bottom: 1rem;
          .userInfo {
            width: 20%;
            background-color: rgb(230,232,242);
            box-sizing: border-box;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .userAvatar {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .userName {
              font-size: 1rem;
              color: #000;
              margin-top: 1rem;
              a {
                color: #000;
                &:hover {
                  color: #e65800 !important;
                  transition: color 0.5s;
                }
              }
            }
          }
          .contentInfo {
            flex: 1;
            background-color: #fff;
            box-sizing: border-box;
            padding: 1rem;
            .infoTitle {
              font-size: 1rem;
              color: #1f2129;
              &.black {
                color: #000;
              }
            }
            .infoTip {
              display: flex;
              margin-top: 1rem;
              .date {
                color: rgba(31,33,41,1);
                font-size: .8rem;
                flex: 1;
              }
            }
            .infoList {
              margin-top: 1rem;
              .infoItem {
                .userContent {
                  display: flex;
                  align-items: center;
                  margin-bottom: 1rem;
                  .userPic {
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .nameUser {
                    font-size: 1rem;
                    color: #000;
                    margin-left: 1rem;
                    a {
                      color: #000;
                      &:hover {
                        color: #e65800 !important;
                        transition: color 0.5s;
                      }
                    }
                  }
                }
                .userIssue {
                  margin-left: 2.5rem;
                  .tlakWrapper {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 1rem;
                  }
                }
              }
            }
            .infoDetail {
              font-size: 1.6rem;
              color: #1f2129;
            }
            .tip {
              display: flex;
              justify-content: flex-end;
              p {
                color: rgba(31,33,41,1);
                font-size: .8rem;
                margin-right: 1rem;
              }
            }
            .fllow-content {
              width: 100%;
              background-color: #fff;
              box-sizing: border-box;
              margin-top: 1rem;
              .textarea-wrapper {
                width: 100%;
                height: 6rem;
                overflow: hidden auto;
                background: #ddd !important;
                border-radius: 4px;
                color: rgb(31, 33, 41);
                font-size: 14px;
                line-height: 20px;
                resize: none;
                white-space: pre-wrap;
                border: none;
                outline: none;
                background-color: rgb(247, 248, 252);
                box-sizing: border-box;
                padding: .6rem;
              }
              .fllow-footer {
                margin-top: 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .footer-button {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  justify-content: center;
                  font-size: 1rem;
                  font-weight: 500;
                  color: rgb(31, 33, 41);
                  width: 5rem;
                  height: 2rem;
                  background-color: #ffd65b;
                }
              }
            }
          }
          .fllow {
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1a1a1a;
            background-color: #ffcc32;
            height: 2rem;
            box-sizing: border-box;
            padding: 0 2rem;
            font-weight: 700;
            text-align: center;
            font-size: 1rem;
            touch-action: manipulation;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
            transition: all 0.1s ease-in-out;
          }
        }
      }
    }
    .articleDetailContentRight {
      width: 32%;
      .articleDetailContentRightTop {
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        padding: .5rem 1rem;
        margin-bottom: 1rem;
        h2 {
          font-size: 1.2rem !important;
          color: #1f2129;
          margin-bottom: .5rem;
        }
        .articleDetailContentRightTopList {
          li {
            width: 100%;
            display: flex;
            box-sizing: border-box;
            padding-bottom: 1rem;
            border-bottom: 1px solid  #e5e7eb;
            margin-bottom: 1rem;
            &:last-child {
              border-bottom: none;
              margin-bottom: 0;
            }
            .imgPic {
              width: 6rem;
              height: 6rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .articleDetailInfo {
              flex: 1;
              margin-left: 1rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              h3 {
                font-size: 1rem;
                color: rgba(31,33,41,1);
                width: 100% !important;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-break: break-all;
                overflow: hidden;
                white-space: normal;
                -webkit-line-clamp: 1;
              }
              .contentInfo {
                color: rgb(96,99,112);
                font-size: 1rem;
                cursor: pointer;
                width: 100% !important;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-break: break-all;
                overflow: hidden;
                white-space: normal;
                -webkit-line-clamp: 2;
                cursor: pointer;
                &:hover {
                  color: #e65800 !important;
                  transition: color 0.5s;
                }
              }
              .tip {
                display: flex;
                p {
                  color: rgba(31,33,41,1);
                  font-size: .8rem;
                  margin-right: 1rem;
                }
              }
            }
          }
        }
      }
      
    }
  }
}
</style>