<template>
    <div class="searchResult">
        <div class="searchResult-title">
            <span>{{total}}</span>
            <span>条符合条件</span>
            <span>已为您按照热度排序</span>
        </div>
        <ul class="mainContentNewsList">
            <li v-for="(item, index) in result" :key="index" @click="openDetails(item)">
              
                <div class="newsHeader">
                    <a href="">
                        <h2>{{ state.countryName }}</h2>
                    </a>
                </div>
                <div class="newsPic">
                    <img v-if="item.source.mediaLink" :src="item.source.mediaLink" alt="" />
                    <img v-else="item.source.mediaLink" :src="onImageError()" alt="" />
                </div>
                <div class="newsTitle">
                    <h3>
                        <a href="">{{ item.headline }}</a>
                    </h3>
                    <h2>
                        <a href="">{{ item.source.name }}</a>
                    </h2>
                    <p class="desc">
                        <span>{{ timestampToDateString(item.createTime, 'mm-dd') }}</span>
                    </p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { getArticleList } from '@/api/discover'
import { timestampToDateString, replaceSpacesWithDash } from '@/utils/utils'
export default {
    computed: {
        state() {
            return this.$store.state.discover.state
        }
    },
    watch: {
        state: {
            handler: function (nv, ov) {
                this.getArticleList()
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            result: [],
            total:0
        }
    },
    methods: {
        async getArticleList() {
            let result = await getArticleList({
                cityId: this.state.city || this.state.country,
                tripTypeId: this.state.triptype,
                pageNo: 1,
                pageSize: 20
            })
            this.result = result.records
            this.total = result.total
            // console.log(this.result)
        },
        onImageError(event) {
            return "https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=372&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 372w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=672&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 672w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=744&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 744w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 972w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1272w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1344&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1344w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1572&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1572w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1872&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1872w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=1944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1944w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=2172&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 2172w, https://images.unsplash.com/photo-1727075252960-7eeff88ae038?q=80&w=2400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 2400w";
        },
        timestampToDateString(timestamp, format) {
            return timestampToDateString(timestamp*1000, format)
        },

        //打开详情页
        openDetails(item) {
            let path = `/travel-news/${replaceSpacesWithDash(item.headline)}`
            let query = {
                id: item.id
            }
            let routeData = this.$router.resolve(({
                path: path, // path 要跳转的路由地址
                query: query
            }))
            window.open(routeData.href, '_blank');
        },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {
        console.log(this.state)
    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
        // this.getArticleList()
    }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.searchResult {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    margin-top: 20px;

    .searchResult-title {
        span {
            font-size: 14px;

            &:nth-child(1) {
                color: red;
            }

            &:nth-child(2) {
                color: #000;
            }

            &:nth-child(3) {
                color: gray;
                margin-left: 10px;
            }
        }
    }

    .mainContentNewsList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        li {
            width: 200px;
            margin: 15px;
            cursor: pointer;

            .newsHeader {
                background-color: rgb(255, 255, 255);

                &:hover {
                    background-color: rgba(255, 204, 50, .12);

                    a {
                        color: rgba(229, 88, 0, 1) !important;

                        h2 {
                            color: rgba(229, 88, 0, 1) !important;
                        }

                        p {
                            color: rgba(229, 88, 0, 1) !important;

                            &::after {
                                border-left: 5px solid rgba(229, 88, 0, 1) !important;
                            }
                        }
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0.5rem;
                    color: rgb(96, 99, 112) !important;

                    h2 {
                        color: rgb(96, 99, 112);
                        font-size: 0.8rem;
                        font-weight: 600;
                    }

                    p {
                        color: rgb(96, 99, 112);

                        &::after {
                            content: "";
                            width: 0;
                            height: 0;
                            margin-left: 3px;
                            display: inline-block;
                            position: relative;
                            top: -1px;
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            border-left: 5px solid #606370;
                            vertical-align: middle;
                        }
                    }
                }
            }

            .newsPic {
                width: 100%;
                height: 10.75rem;
                transition: all 0.5s;
                overflow: hidden;

                &:hover {
                    img {
                        transform: scale(2);
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
            }

            .newsTitle {
                box-sizing: border-box;
                padding: 0.5rem;

                h3 {
                    a {
                        min-height: 2.8rem;
                        font-size: .8rem;
                        // font-weight: 500;
                        width: 100% !important;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                        overflow: hidden;
                        white-space: normal;
                        -webkit-line-clamp: 2;
                        transition: color 0.1s ease-in-out;
                        color: #000;

                        &:hover {
                            color: #e65800;
                        }
                    }
                }

                h2 {
                    a {
                        min-height: 1rem;
                        font-size: 0.8rem;
                        // font-weight: 500;
                        width: 100% !important;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                        overflow: hidden;
                        white-space: normal;
                        -webkit-line-clamp: 2;
                        transition: color 0.1s ease-in-out;
                        color: #969aa9;

                        &:hover {
                            color: #e65800;
                        }
                    }
                }

                .desc {
                    font-size: 0.8rem;
                    margin-top: 0.2rem;
                    text-align: right;

                    span {
                        margin-right: 0.6rem;
                    }
                }
            }
        }
    }

    // ul {
    //     display: flex;
    //     margin-top: 10px;
    //     flex-wrap: wrap;

    //     li {
    //         width: 240px;
    //         border: 1px solid rgba(197, 193, 193, 0.929);
    //         border-radius: 4px;
    //         padding: 12px;
    //         margin-right: 35px;
    //         position: relative;
    //         margin-bottom: 30px;

    //         .tag {
    //             position: absolute;
    //             top: 0;
    //             left: 0;
    //             background: rgba(32, 91, 230, .1);
    //             color: #205be6;
    //         }

    //         .collection {
    //             position: absolute;
    //             top: 5px;
    //             right: 5px;
    //             cursor: pointer;
    //         }

    //         img {
    //             width: 100%;

    //         }

    //         .info {
    //             text-align: center;

    //             p {
    //                 font-size: 16px;
    //                 font-weight: 500;

    //                 &:nth-child(1) {
    //                     margin-bottom: 10px;
    //                 }

    //                 &:nth-child(2) {
    //                     margin-bottom: 10px;

    //                     span:nth-child(1) {
    //                         margin-right: 20px;
    //                     }
    //                 }

    //                 &:nth-child(3) {
    //                     color: red;
    //                     font-size: 14px;
    //                     margin-bottom: 10px;
    //                 }

    //                 &:nth-child(4) {
    //                     color: rgba(100, 100, 100, 0.9);
    //                     font-size: 14px;
    //                 }
    //             }
    //         }

    //         .btns {
    //             display: flex;
    //             align-items: center;
    //             justify-content: space-between;
    //             margin-top: 10px;

    //             p {
    //                 width: 62px;
    //                 height: 28px;
    //                 border: 1px solid rgba(100, 100, 100, 0.3);
    //                 text-align: center;
    //                 line-height: 26px;
    //                 cursor: pointer;

    //                 &:nth-child(1) {
    //                     background: #ffcc32;
    //                 }
    //             }

    //             p:hover {
    //                 color: orange;
    //             }
    //         }
    //     }

    //     li:hover {
    //         box-shadow: 0 0 8px rgba(100, 100, 100, 0.3);
    //     }
    // }
}
</style>