<template>
    <div class="userData">
        <div class="title">My follows</div>
        <ul v-if="haslogin">
            <li>
                <img src="https://p3.dcarimg.com/img/tos-cn-i-dcdx/7a507b6ec14c4ead9c95c336893aa9d2~200x0.webp" alt="">
                <div>
                    <p>小米su7</p>
                    <p>
                        <span>懂车帝</span>
                        <span>4分</span>
                    </p>
                    <p>23万</p>
                </div>
            </li>
            <li>
                <img src="https://p3.dcarimg.com/img/tos-cn-i-dcdx/7a507b6ec14c4ead9c95c336893aa9d2~200x0.webp" alt="">
                <div>
                    <p>小米su7</p>
                    <p>
                        <span>懂车帝</span>
                        <span>4分</span>
                    </p>
                    <p>23万</p>
                </div>
            </li>
            <li>
                <img src="https://p3.dcarimg.com/img/tos-cn-i-dcdx/7a507b6ec14c4ead9c95c336893aa9d2~200x0.webp" alt="">
                <div>
                    <p>小米su7</p>
                    <p>
                        <span>懂车帝</span>
                        <span>4分</span>
                    </p>
                    <p>23万</p>
                </div>
            </li>
        </ul>
        <div v-else class="unlogin">
            <p>Log in discover the content you've been following!</p>
            <b-button type="is-primary" size="is-small" @click="goLogin">login</b-button>
        </div>
    </div>
</template>

<script>
import { getIsLogin } from '@/utils/user'
export default {
    data() {
        return {
            haslogin: getIsLogin()
        }
    },
    methods: {
        //去登录
        goLogin() {
            // sessionStorage.setItem('redirect',this.$route.path)
            this.$router.push('/login')
        },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.userData {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    margin-top: 20px;

    .title {
        font-size: 16px;
        font-weight: 500;
    }

    ul {
        display: flex;

        li {
            align-items: flex-start;
            width: 280px;
            border: 1px solid rgba(197, 193, 193, 0.929);
            border-radius: 4px;
            display: flex;
            padding: 6px;
            margin-right: 15px;

            img {
                width: 100px;
                height: 70px;

            }

            div {
                flex-grow: 1;
                margin-left: 4px;

                p {
                    font-size: 16px;
                    font-weight: 500;

                    &:nth-child(1) {
                        margin-bottom: 10px;
                    }

                    &:nth-child(2) {
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                    }

                    &:nth-child(3) {
                        color: red;
                    }
                }
            }
        }

        li:hover {
            box-shadow: 0 0 8px rgba(100, 100, 100, 0.3);
        }
    }

    .unlogin {
        text-align: center;

        p {
            margin-bottom: 10px;
        }
    }
}
</style>