import postData from "@/common/cHttp";

// 圈组列表
export const getCircleGroupList = (params) => {
  return postData('/api/t/circleGroup/list', {
      json: params,
  })
}

// 大家都在聊 /api/t/circlePost/listRecently
export const getCirclePostListRecently = (params) => {
  return postData('/api/t/circlePost/listRecently', {
      json: params,
  })
}

// 帖子列表 /api/t/circlePost/list
export const getCirclePostList = (params) => {
  return postData('/api/t/circlePost/list', {
      json: params,
  })
}

// 发帖子 /api/t/circlePost/add
export const addCirclePost = (params) => {
  return postData('/api/t/circlePost/add', {
      json: params,
  })
}

// /api/t/circleComment/list  评论列表

export const getCircleCommentList = (params) => {
  return postData('/api/t/circleComment/list', {
      json: params,
  })
}

// /api/t/circleComment/add  发表评论
export const addCircleCommentList = (params) => {
  return postData('/api/t/circleComment/add', {
      json: params,
  })
}