<template>
    <div class="tags">
        <div class="wrapper">
            <b-tag type="is-primary" v-for="item, index in tags" :key="index" closable aria-close-label="Close tag"
                @close="closeTag(item, index)">
                {{ item.value }}
            </b-tag>
        </div>
        <div>
            <b-button @click="reset" size="is-small">Reset</b-button>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        state() {
            return this.$store.state.discover.state
        },
        tags() {
            return this.$store.state.discover.tags
        }
    },
    watch: {
        state: {
            handler: function (nv, ov) {
                // console.log(this.$route, 123)
                // console.log(nv)
                // this.$route.params.cityName = nv.cityName
                // console.log(this.$route, 456)
                // let url = window.location
                // console.log(url)
                // history.pushState('', 'Title', url.replace(window.location.search, '?' + 'aaa=123'))
                this.setTags(nv)
            },
            deep: true
        }
    },
    data() {
        return {

        }
    },
    methods: {
        //标签
        setTags(obj) {
            this.$store.commit("resetTags");
            if (obj.countryName) {
                this.tags.push({
                    id: obj.country,
                    value: obj.countryName,
                    idkey: 'country',
                    valuekey: 'countryName'
                })
            }
            if (obj.cityName) {
                this.tags.push({
                    id: obj.city,
                    value: obj.cityName,
                    idkey: 'city',
                    valuekey: 'cityName'
                })
            }
            if (obj.triptypeName) {
                this.tags.push({
                    id: obj.triptype,
                    value: obj.triptypeName,
                    idkey: 'triptype',
                    valuekey: 'triptypeName'
                })
            }
        },
        closeTag(item) {
            if (item.idkey === 'country') {
                this.state.city = ''
                this.state.cityName = ''
                this.$store.commit("setHotCities", []);
            }
            this.state[item.idkey] = ''
            this.state[item.valuekey] = ''
        },
        reset() {
            this.$store.commit("resetTags");
            this.$store.commit("setHotCities", []);
            this.$store.commit("resetState");
        }
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {
        this.setTags(this.state)
    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;

    .wrapper {
        display: flex;
        align-items: center;
    }
}
</style>